import React from 'react'
import './Works.css'
import Upwork from '../../img/Upwork.png'
import Facebook from '../../img/Facebook.png'
import Fiverr from '../../img/fiverr.png'
import Shopify from '../../img/Shopify.png'
import Amazon from '../../img/amazon.png'
import {motion} from 'framer-motion'
import { Link } from 'react-scroll'

const Works = () => {
  return (
    <div className='works'>
       {/*Left side */}
       <div className="awesome">
            <span>Works for All these</span>
            <span>Brand clients.</span>
            <spane>As a frontend website designer and developer, <br />i excel at translating ideas into pixel-perfect <br />designs and functional websites that seamlessly blend form <br />and function. My expertise in HTML, CSS, JavaScript, and various frontend <br />frameworks enables me to bring web concepts to life, <br />ensuring that every site i create is not only visually appealing but also <br />highly responsive and accessible across different devices and browsers. 
                
            </spane>

            <Link spy={true} to='Contact' smooth={true}>
<button type='' className='btn btn-cv'>Hire me</button>
            </Link>
            
            <div className='blur s-blur' style={{background: '#ABF1FF94'}}></div>

        </div>

        {/*left side */}
        <div className="w-right">
           
            <motion.div 
            initial={{rotate: 45}}
            whileInView ={{rotate: 0}}
            viewport = {{margin: '-40px'}}
            transition={{duration: 3.5, type: 'spring'}}

            className="w-maincircle">
                <div className="w-secCircle">
                    <img src={Upwork} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Amazon} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Shopify} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Fiverr} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Facebook} alt="" />
                </div>
            </motion.div>
            

            {/*background circles */}
            <div className='w-backCircle blueCircle' ></div>
            <div className='w-backCircle yellowCircle' ></div>
        </div>
    </div>
  )
}

export default Works
