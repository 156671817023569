import React from 'react'
import './Services.css'
import HeartEmoji from '../../img/heartemoji.png'
import Glasses from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import Card from '../Card/Card'
import Resume from '../../components/Resume.pdf'
import {motion} from 'framer-motion'

const Services = () => {
  const transition = {duration : 1, type : 'spring'};

  return (
    <div className="services" id='Services'>
        {/*Left side */}
        <div className="awesome">
            <span>My Awesome</span>
            <span>services</span>
            <spane>As a frontend website designer and developer,<br /> I offer a comprehensive range of services to help businesses and <br />individuals create visually appealing, user-friendly, and <br />highly functional websites.
            </spane>

<a href={Resume} download>
<button type='' className='btn btn-cv'>Download CV</button>
</a>
            
            <div className='blur s-blur' style={{background: '#ABF1FF94'}}></div>

        </div>

        {/*Right side*/}
        <div className="cards">
            {/*first card */}
            <motion.div 
            initial={{left: '25rem'}}
            whileInView ={{left: '14rem'}}
            transition={transition}

            style={{left: '14rem'}}>
            <Card 
    emoji = {HeartEmoji}
    heading = {'Design'}
    detail ={"Figma, sketch, Photoshop, Adobe, Adobe xd"} />
            </motion.div>

            {/*second card */}
            <motion.div 
             initial={{left: '-10rem'}}
             whileInView ={{left: '-4rem', top: '12rem'}}
             transition={transition}
 
            style={{top: '12rem', left: '-4rem'}}>
            <Card 
    emoji = {Glasses}
    heading = {'Developer'}
    detail ={"Html, Css, Javascript, React Js, JQuery, Bootstrap 5, MYSQL, SQL, JDBC, JSP"} />
            </motion.div>

            {/*3rd card */}
    <motion.div 
     initial={{top: '25rem'}}
     whileInView ={{left: '12rem', top: '19rem'}}
     transition={transition}

    style={{top: '19rem', left: '12rem'}}>
    <Card 
    emoji = {Humble}
    heading = {'UI/UX'}
    detail ={"Strong grasp of design principles, including layout, color theory, typography, and visual hierarchy."} />
    </motion.div>

    <div className='blur s-blur2' style={{background: "var(--purple)"}}></div>
        </div>
    </div>
  )
}

export default Services
