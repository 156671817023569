import React, { useState } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';
import {useRef} from 'react';

const Contact = () => {
    const form = useRef();
    const [done, setDone] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_qbz5kqh', 'template_p5jvu4o', form.current, 'PXZlA2DJNZlFzI7MF')
          .then((result) => {
              console.log(result.text);
              // Clears form/inputs after button is Selected
      form.current.reset();
      //Give a feedback message after contacting me
              setDone(true);
              
          }, (error) => {
              console.log(error.text);
          });
      };


  return (
    <div className='contact' id='Contact'>
      <div className="w-left">
        <div className="awesome">
            <span>Get in touch</span>
            <span>Contact me</span>
            <div className='blur s-blur1' 
            style={{background: '#ABF1FF94'}}
            >
            </div>
        </div>
      </div>

      <div className="c-right">
        <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='user_name' className='user' placeholder='Name' required/>
            <input type="email" name='user_email' className='user' placeholder='Email' required/>
            <textarea name="message" className='user' placeholder='Message' required></textarea>
            <input type="submit" value="Send" className='btn'/>
            <span>{done && "Thanks for contacting me."}</span>
            <div
            className='blur c-blur'
            style={{background: "var(--purple)"}}
            ></div>
        </form>
      </div>
    </div>
  )
}

export default Contact
