import React from 'react'
import './Footer.css'
import Wave from '../../img/wave.png'
import Github from '@iconscout/react-unicons/icons/uil-github'
import Facebook from '@iconscout/react-unicons/icons/uil-facebook'
import Insta from '@iconscout/react-unicons/icons/uil-instagram'

const Footer = () => {
  return (
    <div className='footer'>
      <img src={Wave} alt="" style={{width: '100%'}}/>
    <div className="footer-content">
        <span>Email: kitivincent207@gmail.com</span>
        <div className="f-icons">
          <a href="https://www.linkedin.com/in/vincent-kiti-3b7b69218/" target='_blank'><Insta color='white' size= '3rem'/></a>
            <a href="https://www.linkedin.com/in/vincent-kiti-3b7b69218/" target='_blank'><Facebook color='white' size='3rem'/></a>
            <a href="https://github.com/vincentjedi" target='_blank'><Github color='white' size='3rem'/></a>
            
        </div>
    </div>
    </div>
  )
}

export default Footer
