import React from 'react';
import './Navbar.css';
import Toggle from '../Toggle/Toggle';
import { Link } from 'react-scroll';

const Navbar = () => {
  return (
    <div className="navbar">
        <div className="left-side">
            <div className="name">Vincent </div>
            <Toggle />
        </div>
        <div className="right-side">
            <div className="list">
                <ul>
                    <Link spy={true} to='Navbar' smooth={true} activeClass='activeClass'>
                    <li>Home</li>
                    </Link>
                    <Link spy={true} to='Services' smooth={true}>
                    <li>Services</li>
                    </Link>
                    <Link spy={true} to='Experience' smooth={true}>
                    <li>Experience</li>
                    </Link>
                    <Link spy={true} to='Portfolio' smooth={true}>
                    <li>Portfolio</li>
                    </Link>
                    
                </ul>
            </div>
            <Link spy={true} to='Contact' smooth={true}>
            <button className='btn n-btn'>Contact</button>
            </Link>
            
        </div>
    </div>
  )
}

export default Navbar
