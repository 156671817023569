import React from 'react'
import './Intro.css'
import Github from '../../img/github.png'
import LinkedIn from '../../img/linkedin.png'
import Instagram from '../../img/instagram.png'
import Vector1 from '../../img/Vector1.png'
import Vector2 from '../../img/Vector2.png'
import Vince from '../../img/vince.jpg'
import thumbup from '../../img/thumbup.png'
import Crown from '../../img/crown.png'
import glassesimoji from '../../img/glassesimoji.png'
import Floating from '../Floatingdiv/Floating'
import { motion } from 'framer-motion'
import { Link} from 'react-scroll'


const Intro = () => {
    const transition = {duration : 2, type: 'spring'}

  return (
    <div className="intro" >
        <div className="l-side">
            <div className="i-name">
                <span>Hi, I Am</span>
                <span>Vincent Kiti</span>
                <span>Vincent is the architect behind visually stunning and user-friendly websites that leave a lasting impression. With a keen eye for design aesthetics and a deep understanding of user experience principles, i have the ability to transform a simple webpage into an engaging digital masterpiece.</span>
            </div>

            <Link spy={true} to='Contact' smooth={true}>
            <button className='btn btn-hire'>Hire me</button>
            </Link>
            
            <div className="icons">
                <a href="https://github.com/vincentjedi" target='_blank'>
                <img src={Github} alt="" />
                </a>
                <a href="https://www.linkedin.com/in/vincent-kiti-3b7b69218/" target='_blank'>
                <img src={LinkedIn} alt="" />
                </a>
                <a href="https://www.linkedin.com/in/vincent-kiti-3b7b69218/" target='_blank'>
                <img src={Instagram} alt="" />
                </a>
                
            </div>
        </div>
        <div className="r-side">
            <img src={Vector1} alt="" />
            <img src={Vector2} alt="" />
            <img src={Vince} alt="" />
            <motion.img 
            initial={{left: '-36%'}}
            whileInView ={{left: '-24%'}}
            transition={transition}

            src={glassesimoji} alt="" />

            <motion.div 
            initial={{top: '-4%', left: '74%'}}
            whileInView ={{left: '68%'}}
            transition={transition}

            style={{top: '-4%', left: '68%'}}
            className='floating-div'
            >
                <Floating image={Crown} txt1='Web' txt2='Developer.'/>
            </motion.div>

            <motion.div 
            initial={{left: '9rem', top: '18rem'}}
            whileInView ={{left: '0rem'}}
            transition={transition}

            style={{top: '18rem', left: '0rem'}}
            className='floating-div'
            >
                <Floating image={thumbup} txt1='Best Design' txt2='Award'/>
            </motion.div>

            {/*Blur divs */}
            <div className="blur" style={{background: 'rgb(238 210 255)'}}></div>
            <div className="blur"
            style={{background: '#C1F5FF', top: '17rem', width: '21rem', height:'11rem',left:'-9rem'
        }}
            ></div>
        </div>
    </div>
  )
}

export default Intro
